import { OrderDetails } from "../../models/container/order-details";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    ServiceLocator,
    ShipmentsApi,
    ShipmentsApiServiceKey
} from "@services";

export type OrderDetailsFetchParams = {
    orderNumber: string;
    deliveryNumber?: string;
};

export const fetchOrderDetailsAsync = createAsyncThunk(
    "Shipment/fetchOrderDetails",
    async (input: OrderDetailsFetchParams) => {
        const api = ServiceLocator.get<ShipmentsApi>(ShipmentsApiServiceKey);
        const response = await api.getOrderDetails(
            input.orderNumber,
            input.deliveryNumber
        );
        return response.data;
    }
);

interface OrderDetailsState {
    isLoading: boolean;
    orderDetails: OrderDetails | null | undefined;
}

const emptyOrderDetails = {
    deliveries: [],
    eac: "",
    modeOfTransportation: "",
    orderNumber: "",
    creditStatus: "",
    purchaseOrderNumber: "",
    termsOfPayment: ""
} as OrderDetails;

export const orderSlice = createSlice({
    name: "orderDetail",
    initialState: {
        orderDetails: null,
        isLoading: false
    } as OrderDetailsState,

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderDetailsAsync.pending, (state) => {
                state.isLoading = true;
            })

            .addCase(
                fetchOrderDetailsAsync.fulfilled,
                (state: OrderDetailsState, action) => {
                    state.isLoading = false;
                    state.orderDetails = action.payload
                        ? action.payload
                        : emptyOrderDetails;
                }
            );
    }
});

export default orderSlice.reducer;
